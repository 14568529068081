import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './weiSalse.component.html',
    styleUrls: ['./weiSalse.component.css'],
})

export class WeiSalseComponent implements OnInit {
  constructor(
  ) {}

  ngOnInit() {
  }
}
