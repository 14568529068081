import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BakingComponent } from './baking.component';






@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    BakingComponent
  ]
})

export class BakingModule {

}
