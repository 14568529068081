import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {PageNotFoundComponent} from './not-found.component';
import { HomeComponent } from './module/home/home.component';
import { LoginComponent } from './module/login/login.component';
import { RegisterComponent } from './module/register/register.component';
import { YunPosComponent } from './module/yunPos/yunPos.component';
import { AppOrderComponent } from './module/appOrder/appOrder.component';
import { VendorManagerComponent } from './module/vendorManager/vendorManager.component';
import { VendorAppComponent } from './module/vendorApp/vendorApp.component';
import { WeiSalseComponent } from './module/weiSalse/weiSalse.component';
import { StoreAppComponent } from './module/storeApp/storeApp.component';
import { DataAnalysisComponent } from './module/dataAnalysis/dataAnalysis.component';
import { BakingComponent } from './module/baking/baking.component';
import { MealComponent } from './module/meal/meal.component';
import { ProjectComponent } from './module/project/project.component';



const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'yunPos', component: YunPosComponent },
  { path: 'appOrder', component: AppOrderComponent },
  { path: 'vendorManager', component: VendorManagerComponent },
  { path: 'vendorApp', component: VendorAppComponent },
  { path: 'weiSalse', component: WeiSalseComponent },
  { path: 'storeApp', component: StoreAppComponent },
  { path: 'dataAnalysis', component: DataAnalysisComponent },
  { path: 'baking', component: BakingComponent },
  { path: 'meal', component: MealComponent },
  { path: 'project', component: ProjectComponent },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes)],
  exports: [ RouterModule ]
})

export class AppRoutingModule {}

