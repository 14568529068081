import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeiSalseComponent } from './weiSalse.component';




@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    WeiSalseComponent
  ]
})

export class WeiSalseModule {

}
