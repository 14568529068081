import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorAppComponent } from './vendorApp.component';




@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    VendorAppComponent
  ]
})

export class VendorAppModule {

}
