import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './vendorManager.component.html',
    styleUrls: ['./vendorManager.component.css'],
})

export class VendorManagerComponent implements OnInit {
  constructor(
  ) {}

  ngOnInit() {
  }
}
