import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.css'],
  // animations: [
  //   trigger('flyInOut', [
  //     state('in', style({opacity: 1, transform: 'translateX(0)'})),
  //     transition('void => *', [
  //       style({
  //         opacity: 1,
  //         transform: 'translateX(100%)'
  //       }),
  //       animate('1.5s ease-in')
  //     ]),
  //     transition('* => void', [
  //       animate('1.5s 0.1s ease-in', style({
  //         opacity: 1,
  //         transform: 'translateX(-100%)'
  //       }))
  //     ])
  //   ])
  // ]
  animations: [
    trigger('imgMove', [
     /** 不显示 */
     state('off', style({'display': 'none', 'z-index': '0', 'transform': 'translateX(0)'})),
     /** 上一张图片 */
     state('prev', style({'z-index': '1',
     'transform': 'translateX(-100%)'})),
     /** 下一张图片 */
     state('next', style({'z-index': '2', 'transform': 'translateX(100%)'})),
     /** 当前图片 */
     state('on', style({'z-index': '3', 'transform': 'translateX(0)'})),
     transition('prev=>on', [
      animate('1.5s ease-in')
     ]),
     transition('next=>on', [
      animate('1.5s ease-in')
     ]),
     transition('on=>prev', [
      animate('1.5s ease-in')
     ]),
     transition('on=>next', [
      animate('1.5s ease-in')
     ])
    ])
   ]
})
export class SlideComponent implements OnInit {
  public current;
  public imgs = [0, 1];
  constructor() {
    // setInterval(() => {
    //   this.current = (this.current + 1) % 2;
    // }, 5000);
   }

  ngOnInit() {
    this.current = 0;
  }
  public ImgState(index) {
    if (this.imgs && this.imgs.length) {
     if (this.current === 0) {
      return index === 0 ? 'on' :
      index === 1 ? 'next' :
      index === this.imgs.length - 1 ? 'prev' :
      'off';
     } else if (this.current === this.imgs.length - 1) {
      return index === this.imgs.length - 1 ? 'on' :
      index === this.imgs.length - 2 ? 'prev' :
      index === 0 ? 'next' :
      'off';
     }
     switch (index - this.current) {
      case 0:
       return 'on';
      case 1:
       return 'next';
      case -1:
       return 'prev';
      default:
       return 'off';
     }
    } else {
     return 'off';
    }
   }

  public Next() {
    this.current = (this.current + 1) % this.imgs.length;
   }
   public Prev() {
    this.current = this.current - 1 < 0 ? this.imgs.length - 1 : this.current - 1;
   }
   public goTo(index) {
     this.current = index;
   }
}
