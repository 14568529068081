import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreAppComponent } from './storeApp.component';




@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    StoreAppComponent
  ]
})

export class StoreAppModule {

}
