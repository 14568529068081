import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css'],
})

export class RegisterComponent implements OnInit {
  constructor(
  ) {}

  ngOnInit() {
  }
}
