import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.css'],
})

export class ProjectComponent implements OnInit {
  constructor(
  ) {}

  ngOnInit() {
  }
}
