import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './dataAnalysis.component.html',
    styleUrls: ['./dataAnalysis.component.css'],
})

export class DataAnalysisComponent implements OnInit {
  constructor(
  ) {}

  ngOnInit() {
  }
}
