import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('floatRight', [
      state('in', style({left: '0px'})),
      state('out', style({left: '-202px'})),
      transition('in =>out', [
        animate('0.3s ease-in')
      ]),
      transition('out => in', [
        animate('0.3s ease-in')
      ])
    ])
  ]
})
export class AppComponent {
  type = 'status';
  sliderStatus = 'out';
  isHome = true;
  navtMenu = 'home';
  constructor(
    private router: Router,
  ) {}
  menus = [
    {
      'title': '产品',
      'menuToggle': false,
      'menu': [
        {'text': ' 智企道云POS', 'url': 'yunPos', 'borderRight': true},
        {'text': ' 供应链管理', 'url': 'vendorManager', 'borderRight': true},
        {'text': ' 微会员微营销', 'url': 'weiSalse'},
        {'text': ' 移动点单', 'url': 'appOrder', 'borderRight': true },
        {'text': ' 移动供应商平台', 'url': 'vendorApp', 'borderRight': true },
        {'text': ' 移动门店', 'url': 'storeApp'},
        {'text': ''},
        {'text': ''},
        {'text': ' 数据分析', 'url': 'dataAnalysis'},
      ]
    }
  ];
  menus2 = [
    {
      'title': '方案',
      'menuToggle': false,
      'menu': [
        {'text': '连锁餐饮智慧门店场景方案', 'url': 'meal'},
        {'text': ' 连锁烘焙智慧门店场景方案', 'url': 'baking'},
        {'text': ' 连锁街饮智慧门店场景方案'},
      ]
    }
  ];
  openMenu(item) {
    item.menuToggle = true;
  }
  offMenu(item) {
    item.menuToggle = false;
  }
  toggleMenu(menu) {
    if (menu === this.navtMenu) {
      this.navtMenu = '';
    } else {
      this.navtMenu = menu;
    }
    // item.menuToggle = !item.menuToggle;
  }
  openSlider() {
    this.sliderStatus = 'in';
  }
  offSlider() {
    this.sliderStatus = 'out';
  }
  toggleSlider() {

    if (this.sliderStatus === 'out') {
      this.sliderStatus = 'in';
    } else {
      this.sliderStatus = 'out';
    }
  }
  login() {
    this.isHome = false;
    this.router.navigate(['login']);
  }
  register() {
    this.router.navigate(['register']);
  }
  goHome() {
    this.navtMenu = 'home';
    this.router.navigate(['home']);
  }
  goProject() {
    this.navtMenu = 'project';
    this.router.navigate(['project']);
  }
  navTo(url, item) {
  //   if (!url) {
  //     return;
  //   }
  //  if (url === 'baking' || url === 'meal') {
  //    this.navtMenu = 'fangan';
  //  } else {
  //    this.navtMenu = 'product';
  //  }
  //   item.menuToggle = false;
    this.router.navigate([url]);
    this.navtMenu = '';
  }
}
