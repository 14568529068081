import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YunPosComponent } from './yunPos.component';




@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    YunPosComponent
  ]
})

export class YunPosModule {

}
