import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { PageNotFoundComponent } from './not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './module/home/home.module';
import { LoginModule } from './module/login/login.module';
import { RegisterModule } from './module/register/register.module';
import { YunPosModule } from './module/yunPos/yunPos.module';
import { AppOrderModule } from './module/appOrder/appOrder.module';
import { VendorManagerModule } from './module/vendorManager/vendorManager.module';
import { VendorAppModule } from './module/vendorApp/vendorApp.module';
import { WeiSalseModule } from './module/weiSalse/weiSalse.module';
import { StoreAppModule } from './module/storeApp/storeApp.module';
import { DataAnalysisPosModule } from './module/dataAnalysis/dataAnalysis.module';
import { BakingModule } from './module/baking/baking.module';
import { MealModule } from './module/meal/meal.module';
import { ProjectModule } from './module/project/project.module';




@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HomeModule,
    LoginModule,
    RegisterModule,
    YunPosModule,
    AppRoutingModule,
    AppOrderModule,
    VendorManagerModule,
    VendorAppModule,
    WeiSalseModule,
    StoreAppModule,
    DataAnalysisPosModule,
    BakingModule,
    MealModule,
    ProjectModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
