import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataAnalysisComponent } from './dataAnalysis.component';




@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    DataAnalysisComponent
  ]
})

export class DataAnalysisPosModule {

}
