import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorManagerComponent } from './vendorManager.component';





@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    VendorManagerComponent
  ]
})

export class VendorManagerModule {

}
