import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppOrderComponent } from './appOrder.component';





@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AppOrderComponent
  ]
})

export class AppOrderModule {

}
