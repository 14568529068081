import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MealComponent } from './meal.component';






@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    MealComponent
  ]
})

export class MealModule {

}
